import React from "react";

const Usermode = ({ onProfile }) => {
  return (
    <button id="usermode" onClick={onProfile}>
      <img
        src={`${process.env.PUBLIC_URL}/images/icons/user.svg`}
        alt="Sun images"
      />
    </button>
  );
};
export default Usermode;
