import React from "react";
import SectionTitle from "../../components/common/SectionTitle";
import BreadcrumbOne from "../../components/common/BreadcrumbOne";
import ItemCard from "../../elements/portfolio/item-card";
import useSWR from "swr";
import { AdviceApi } from "../../api";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
import VideoOne from "../../elements/video/VideoOne";
import { Triangle } from "react-loader-spinner";
const AdviceScreen = () => {
  const { data, isLoading } = useSWR("swr.advices", async () => {
    const res = await AdviceApi.getAdvices();
    return res;
  });

  return (
    // <>
    //   <SEO title="Portfolio || s69 - React Business  Template" />
    //   <Layout>
    //     <BreadcrumbOne
    //       title="Танд зориулсан зөвлөгөө"
    //       rootUrl="/"
    //       parentUrl="Home"
    //       currentUrl="Зөвлөгөө"
    //     />

    //     <div className="main-content">
    //       <div className="rwt-portfolio-area rn-section-gap">
    //         <div className="container">
    //           <div className="row">
    //             <div className="col-lg-12">
    //               <SectionTitle
    //                 textAlign="text-center"
    //                 radiusRounded=""
    //                 subtitle="Зөвлөгөөнүүд"
    //                 title="Зөвлөгөө!"
    //                 description=""
    //               />
    //             </div>
    //           </div>
    //           <ItemCard
    //             Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio"
    //             data={data?.data}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </Layout>
    // </>
    <>
      <SEO title="s69.mn - Зөвлөмж" />
      <Layout>
        {isLoading ? (
          <div
            className="d-flex justify-content-center rn-section-gap"
            style={{ alignSelf: "center" }}
          >
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#fb5b82"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="main-content">
            {/* Start Elements Area  */}
            <div className="rwt-video-popup-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      // subtitle="Зөвлөмж"
                      title="Зөвлөмж"
                      description=""
                    />
                  </div>
                </div>
                <VideoOne data={data?.data} />;
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
export default AdviceScreen;
