import React from "react";
import { Button, Modal } from "react-bootstrap";
import SectionTitle from "../components/common/SectionTitle";
import PricingOne from "./PricingOne";
const QpayAskModal = ({ show, handleClose, handlePay, loading }) => {
  const onClose = () => {
    handleClose();
    window.location.reload();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <div className="theme-shape" style={{ backgroundColor: "black" }}>
        <PricingOne handlePay={handlePay} onClose={onClose} loading={loading} />
      </div>
    </Modal>
  );
};

export default QpayAskModal;
