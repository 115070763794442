import React, { useCallback, useState } from "react";
import { FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";
import { AuthApi } from "../../api";
import Logo from "../common/Logo";
import QpayAskModal from "../QpayAskModal";
import QpayModal from "./Qpay";
const ProfileModal = ({ show: open, onClose }) => {
  const { data: user } = useSWR("swr.user.me");
  const history = useHistory();
  var elements = document.querySelectorAll(
    ".popup-login-menu .has-droupdown > a"
  );
  var elementsTwo = document.querySelectorAll(
    ".popup-login-menu .with-megamenu > a"
  );
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (var i in elementsTwo) {
    if (elementsTwo.hasOwnProperty(i)) {
      elementsTwo[i].onclick = function () {
        this.parentElement
          .querySelector(".rn-megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  //
  const logout = async () => {
    try {
      await AuthApi.logout();
      localStorage.removeItem("s69User");
      history.push("/");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [qpayModal, setQpayModal] = useState(false);
  const onQpay = () => {
    setQpayModal((prev) => !prev);
  };
  const [show, setShow] = useState(false);
  const [qpay, setQpay] = useState();
  const handleClose = () => {
    setShow(false);
  };

  const onPayment = async () => {
    setLoading(true);
    try {
      const res = await AuthApi.getInvoince({ userId: user?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setShow(false);
      setQpayModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const checkPayment = useCallback(async () => {
    setCheckLoading(true);
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, user?._id);
      if (res.success) {
        window.location.reload();
        // Toast("Төлбөр төлөгдсөн");
      }
      setCheckLoading(false);
    } catch (err) {
      // Toast("Төлбөр төлөгдөөгүй", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   type: "warning",
      // });
      alert(
        "Төлбөр төлөгдөөгүй байна. Хэрэв та төлсөн бол бүртгэлээ гаргаад оруулна уу"
      );
      setCheckLoading(false);
    }
  }, [qpay?.invoiceId]);

  return (
    <>
      <div className={`popup-login-menu ${open ? "active" : ""}`}>
        <div className="inner">
          <div className="header-top">
            <Logo
              image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
              image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
            />
            <div className="close-menu">
              <span className="close-button" onClick={onClose}>
                <FiX />
              </span>
            </div>
          </div>
          <img
            src={require("../../assets/logo.png")}
            alt="Corporate React Template"
            style={{
              borderRadius: 100,
              width: 80,
              height: 80,
              alignSelf: "center",
              objectFit: "contain",
            }}
            className="mt--30 "
          />
          <h2
            className="title mt--10"
            style={{ alignSelf: "center", fontSize: 20 }}
          >
            {user?.username}
          </h2>
          {user?.isPayment ? (
            <h3
              className="text-success "
              style={{ fontSize: 16, alignSelf: "center" }}
            >
              Идэвхтэй
            </h3>
          ) : (
            <h3
              className="text-danger"
              style={{ fontSize: 16, alignSelf: "center" }}
            >
              Идэвхгүй
            </h3>
          )}
          {!user?.isPayment && (
            <Link
              className="btn-default text-underline"
              to="/#"
              onClick={() => {
                setShow(true);
                onClose();
              }}
              style={{ margin: 10 }}
            >
              <span>Төлбөр төлөх</span>
            </Link>
          )}
          <Link
            className="btn-default text-underline"
            to="/privacy"
            style={{ margin: 10 }}
          >
            <span>Үйлчилгээний нөхцөл</span>
          </Link>
          <Link
            className="btn-default text-underline"
            to="#"
            style={{ margin: 10 }}
          >
            <span>Анхан шатны зөвлөгөө</span>
          </Link>
          <Link
            className="btn-default text-underline"
            to="#"
            style={{ margin: 10 }}
            onClick={logout}
          >
            <span>Гарах</span>
          </Link>
        </div>
      </div>
      <QpayAskModal
        show={show}
        handleClose={handleClose}
        handlePay={onPayment}
        loading={loading}
      />
      <QpayModal
        onClose={onQpay}
        show={qpayModal}
        checkPayment={checkPayment}
        qpay={qpay}
        checkLoading={checkLoading}
      />
    </>
  );
};
export default ProfileModal;
