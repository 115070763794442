import React, { useState } from "react";
import { FiCalendar, FiEye } from "react-icons/fi";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import "yet-another-react-lightbox/styles.css";

const BlogDetailsContentTwo = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const toggleOpen = (state) => () => setOpen(state);

  const updateIndex = ({ index: current }) => {
    setIndex(current);
  };

  const slides = data.images?.map((image) => {
    return {
      src: image.url,
      width: 540,
      height: 540,
    };
  });

  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient">{data.title}</h1>
                </div>
                <ul className="rn-meta-list">
                  <li>
                    <FiEye />
                    {data.seen}
                  </li>
                  <li>
                    <FiCalendar />
                    {data?.createdAt?.slice(0, 10)}
                  </li>
                </ul>
                <div className="thumbnail alignwide mt--60">
                  <Lightbox
                    index={index}
                    slides={slides}
                    plugins={[Inline]}
                    on={{
                      view: updateIndex,
                      click: toggleOpen(true),
                    }}
                    carousel={{
                      padding: 0,
                      spacing: 0,
                      imageFit: "contain",
                      borderRadius: 20,
                    }}
                    inline={{
                      style: {
                        width: "100%",
                        maxWidth: "900px",
                        height: "580px",
                        aspectRatio: "3 / 2",
                        margin: "0 auto",
                        borderRadius: 20,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={slides}
        on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />
    </>
  );
};
export default BlogDetailsContentTwo;
