import { useState, useRef, useCallback } from "react";
import { FiMenu } from "react-icons/fi";
import useSWR from "swr";
import useStickyHeader from "./useStickyHeader";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import Logo from "./Logo";
import LoginModal from "../auth/Login";
import RegisterModal from "../auth/Register";
import ProfileModal from "../auth/Profile";
import Usermode from "../auth/Usermode";
import QpayModal from "../auth/Qpay";
import QpayAskModal from "../QpayAskModal";
import { AuthApi } from "../../api";

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const { data } = useSWR("swr.user.me", async () => {
    const res = await AuthApi.me();
    return res.data;
  });

  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);

  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const [loginModal, setLoginModal] = useState(false);
  const onLogin = () => {
    setLoginModal((prev) => !prev);
  };
  const [profileModal, setProfileModal] = useState(false);
  const onProfile = () => {
    setProfileModal((prev) => !prev);
  };
  const [registerModal, setRegisterModal] = useState(false);
  const onRegister = () => {
    setRegisterModal((prev) => !prev);
  };
  const [qpayModal, setQpayModal] = useState(false);
  const onQpay = () => {
    setQpayModal((prev) => !prev);
  };

  const [show, setShow] = useState(false);
  const [qpay, setQpay] = useState();
  const handleClose = () => {
    setShow(false);
  };
  const onPayment = async () => {
    setLoading(true);
    try {
      const res = await AuthApi.getInvoince({ userId: data?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setShow(false);
      setQpayModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };

  const checkPayment = useCallback(async () => {
    setCheckLoading(true);
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, data?._id);
      console.log(res, "check");
      if (res.success) {
        window.location.reload();
        // Toast("Төлбөр төлөгдсөн");
      }
      setCheckLoading(false);
    } catch (err) {
      setCheckLoading(false);
      alert(
        "Төлбөр төлөгдөөгүй байна. Хэрэв та төлсөн бол бүртгэлээ гаргаад оруулна уу"
      );
      // Toast("Төлбөр төлөгдөөгүй", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   type: "warning",
      // });
    }
  }, [qpay?.invoiceId, data?._id]);

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
              />
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav onLogin={onLogin} />
                </nav>
                {!data && (
                  <>
                    <div className="header-btn">
                      <button
                        className={`btn-default ${btnStyle}`}
                        onClick={onRegister}
                      >
                        Бүртгүүлэх
                      </button>
                    </div>
                  </>
                )}
                {data && !data?.isPayment && (
                  <div className="header-btn">
                    <button
                      className={`btn-default ${btnStyle}`}
                      onClick={() => setShow(true)}
                    >
                      Эрх авах{" "}
                    </button>
                  </div>
                )}
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                <Darkmode />
                {data && <Usermode onProfile={onProfile} />}
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
      <LoginModal show={loginModal} onClose={onLogin} />
      <RegisterModal show={registerModal} onClose={onRegister} />
      <ProfileModal show={profileModal} onClose={onProfile} />
      <QpayAskModal
        show={show}
        handleClose={handleClose}
        handlePay={onPayment}
        loading={loading}
      />
      <QpayModal
        onClose={onQpay}
        show={qpayModal}
        checkPayment={checkPayment}
        qpay={qpay}
        checkLoading={checkLoading}
      />
    </>
  );
};
export default HeaderOne;
