import React, { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import PricingTwo from "./PricingTwo";
import { AuthApi } from "../api";
const AdultAskModal = ({ show, handleClose }) => {
  const onClose = () => {
    handleClose();
  };

  const onDelete = useCallback(async () => {
    try {
      const res = await AuthApi.adultVerify();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <div className="theme-shape" style={{ backgroundColor: "black" }}>
        <PricingTwo handlePay={onDelete} onClose={onClose} />
      </div>
    </Modal>
  );
};

export default AdultAskModal;
