import React from "react";
import { FiX } from "react-icons/fi";
import Nav from "./Nav";
import Logo from "./Logo";

const MobileMenu = ({ show, onClose }) => {
  var elements = document.querySelectorAll(
    ".popup-mobile-menu .has-droupdown > a"
  );
  var elementsTwo = document.querySelectorAll(
    ".popup-mobile-menu .with-megamenu > a"
  );
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (var i in elementsTwo) {
    if (elementsTwo.hasOwnProperty(i)) {
      elementsTwo[i].onclick = function () {
        this.parentElement
          .querySelector(".rn-megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const onAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=mn.s69",
      "_blank"
    );
  };
  const onApple = () => {
    window.open("https://apps.apple.com/us/app/s69-mn/id6479241731", "_blank");
  };

  return (
    <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo
            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
            image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
          />
          <div className="close-menu">
            <span className="close-button" onClick={onClose}>
              <FiX />
            </span>
          </div>
        </div>
        <Nav />
        <div
          style={{
            position: "absolute",
            bottom: 150,
            right: 70,
            left: 70,
          }}
        >
          <h5 style={{ textAlign: "center" }}>Апп татах</h5>

          <img
            src={require("../../assets/android.png")}
            style={{
              width: 200,
              height: 50,
              objectFit: "contain",
            }}
            alt="android"
            onClick={onAndroid}
          />
          <img
            src={require("../../assets/ios.png")}
            style={{
              width: 200,
              height: 50,
              objectFit: "contain",
              marginTop: 10,
            }}
            alt="ios"
            onClick={onApple}
          />
        </div>
      </div>
    </div>
  );
};
export default MobileMenu;
