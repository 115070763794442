import { get } from "../helpers/api_helper";

export const getAdvices = async () => {
  const res = get("/advices?page=1&limit=10");
  return res;
};
export const getAdvice = async (id) => {
  const res = get(`/advices/${id}`);
  return res;
};
