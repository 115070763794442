import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import SEO from "../components/common/SEO";
import PricingOne from "./PricingOne";
import useSWR from "swr";
import QpayModal from "../components/auth/Qpay";
import { AuthApi } from "../api";
const PaymentVerifyScreen = () => {
  const history = useHistory();
  const { data: user } = useSWR("swr.user.me", async () => {
    const res = await AuthApi.me();
    return res.data;
  });

  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);

  const [qpayModal, setQpayModal] = useState(false);
  const onQpay = () => {
    setQpayModal((prev) => !prev);
  };
  const [qpay, setQpay] = useState();

  const onPayment = async () => {
    setLoading(true);
    try {
      const res = await AuthApi.getInvoince({ userId: user?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setQpayModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const checkPayment = useCallback(async () => {
    setCheckLoading(true);
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, user?._id);
      if (res.success) {
        window.location.reload();
        // Toast("Төлбөр төлөгдсөн");
      }
      setCheckLoading(false);
    } catch (err) {
      setCheckLoading(false);
      alert(
        "Төлбөр төлөгдөөгүй байна. Хэрэв та төлсөн бол бүртгэлээ гаргаад оруулна уу"
      );
    }
  }, [qpay?.invoiceId]);
  const logout = async () => {
    try {
      await AuthApi.logout();
      localStorage.removeItem("s69User");
      history.push("/");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <SEO title="s69.mn || Төлбөр төлөх" />
      <main className="page-wrapper">
        <div
          className="slider-area slider-style-1 variation-default height-950 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/lesson16.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title display-one">
                    <span className="theme-gradient">s69.mn</span>
                  </h1>

                  <div style={{ height: "20px" }} />
                  <PricingOne
                    logout={logout}
                    onPayment={onPayment}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <QpayModal
        onClose={onQpay}
        show={qpayModal}
        checkPayment={checkPayment}
        qpay={qpay}
        checkLoading={checkLoading}
      />
    </>
  );
};

export default PaymentVerifyScreen;
