import React from "react";
import { FiX } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { AuthApi } from "../../api";
import LoginForm from "../form/login-form";
import Logo from "../common/Logo";
const LoginModal = ({ show, onClose }) => {
  var elements = document.querySelectorAll(
    ".popup-login-menu .has-droupdown > a"
  );
  var elementsTwo = document.querySelectorAll(
    ".popup-login-menu .with-megamenu > a"
  );
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (var i in elementsTwo) {
    if (elementsTwo.hasOwnProperty(i)) {
      elementsTwo[i].onclick = function () {
        this.parentElement
          .querySelector(".rn-megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await AuthApi.login(data);
      localStorage.setItem("s69User", JSON.stringify(res.token));
      window.location.reload();
    } catch (err) {
      setError("password", { message: err.response.data.error.message });
    }
  };

  return (
    <div className={`popup-login-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo
            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
            image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
          />
          <div className="close-menu">
            <span className="close-button" onClick={onClose}>
              <FiX />
            </span>
          </div>
        </div>
        <h1>Нэвтрэх</h1>
        <LoginForm
          register={register}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};
export default LoginModal;
