import React from "react";
import BlogDetailsContent from "../../components/blog/BlogDetailsContent";
import useSWR from "swr";
import { ArticleApi } from "../../api";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
import { Triangle } from "react-loader-spinner";
const ArticleDetail = ({
  match: {
    params: { id },
  },
}) => {
  const { data, isLoading } = useSWR(`swr.articles.${id}`, async () => {
    const res = await ArticleApi.getArticle(id);
    return res.data;
  });
  if (!data) {
    return null;
  }
  return (
    <>
      <SEO title="s69 || Нийтлэл дэлгэрэнгүй" />
      <Layout>
        {isLoading ? (
          <div
            className="d-flex justify-content-center rn-section-gap"
            style={{ alignSelf: "center" }}
          >
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#fb5b82"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="rn-blog-details-area">
            <BlogDetailsContent data={data} />
          </div>
        )}
      </Layout>
    </>
  );
};
export default ArticleDetail;
