import { get } from "../helpers/api_helper";

export const getLessons = async () => {
  const res = get("/lessons?page=1&limit=50");
  return res;
};
export const getLesson = async (id) => {
  const res = get(`/lessons/${id}`);
  return res;
};
