import React from "react";
import { useForm } from "react-hook-form";

const LoginForm = ({ register, errors, onSubmit }) => {
  return (
    <form
      className={`contact-form-2`}
      action=""
      style={{ marginLeft: 12, marginRight: 12 }}
      onSubmit={onSubmit}
    >
      <div className="form-group" style={{ marginTop: `10px` }}>
        <strong style={{ color: "white" }}>Нэр</strong>
        <input
          type="text"
          name="username"
          placeholder="Нэр"
          {...register("username", { required: "Заавал оруулна уу" })}
          className={errors.username ? "border-danger" : ""}
          aria-invalid={errors.username ? "true" : "false"}
        />
        {errors.username && (
          <span className="text-danger">{errors.username.message}</span>
        )}
      </div>
      <div className="form-group" style={{ marginTop: `10px` }}>
        <strong style={{ color: "white" }}>Нууц үг</strong>
        <input
          type="password"
          name="password"
          placeholder="Нууц үг"
          {...register("password", { required: "Заавал оруулна уу" })}
          aria-invalid={errors.password ? "true" : "false"}
          className={errors.password ? "border-danger" : ""}
        />
        {errors.password && (
          <span className="text-danger">{errors.password.message}</span>
        )}
      </div>
      <button className="btn-default">Нэвтрэх</button>
    </form>
  );
};

export default LoginForm;
