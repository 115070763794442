import PageScrollTop from "../components/pageToTop/PageScrollTop";
import { Switch, Route, Redirect } from "react-router-dom";
import useSWR from "swr";
import { AuthApi } from "../api";
import LoginScreen from "../pages/auth/login";
import ArticleScreen from "../pages/article/article";
import ArticleDetail from "../pages/article/article-detail";
import AdviceScreen from "../pages/advice/advice";
import LessonScreen from "../pages/lesson/lesson";
import LessonDetail from "../pages/lesson/lesson-detail";
import StoryScreen from "../pages/story/story";
import StoryDetailScreen from "../pages/story/story-detail";
import PrivacyScreen from "../pages/privacy";
import Error from "../pages/Error";
import authHeader from "../helpers/auth-token-header";
import RegisterScreen from "../pages/auth/register";
import PaymentVerifyScreen from "../pages/payment-verify";
const MyRoute = () => {
  const token = `${authHeader().Authorization}`;
  const { data } = useSWR(["swr.user.me", token], async () => {
    return await AuthApi.me();
  });

  return (
    <PageScrollTop>
      {data ? (
        <>
          {data?.data?.isPayment ? (
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL + "/"}`}
                exact
                component={ArticleScreen}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/article-detail/:id"}`}
                exact
                component={ArticleDetail}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/advice"}`}
                exact
                component={AdviceScreen}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/lesson"}`}
                exact
                component={LessonScreen}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/lesson/:id"}`}
                exact
                component={LessonDetail}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/exercise"}`}
                exact
                component={StoryScreen}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/exercise/:id"}`}
                exact
                component={StoryDetailScreen}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/privacy"}`}
                exact
                component={PrivacyScreen}
              />
              <Route
                path={`${process.env.PUBLIC_URL + "/error"}`}
                exact
                component={Error}
              />
              <Redirect to="/" />
            </Switch>
          ) : (
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL + "/payment"}`}
                exact
                component={PaymentVerifyScreen}
              />
              <Redirect to="/payment" />
            </Switch>
          )}
        </>
      ) : (
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={LoginScreen}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/register"}`}
            exact
            component={RegisterScreen}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/privacy"}`}
            exact
            component={PrivacyScreen}
          />
          <Redirect to="/" />
        </Switch>
      )}
    </PageScrollTop>
  );
};

export default MyRoute;
