import React from "react";
import Copyright from "../footer/Copyright";
import HeaderOne from "./HeaderOne";
import CopyrightTwo from "../footer/CopyrightTwo";
const Layout = ({ children }) => {
  return (
    <>
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        {children}
        <CopyrightTwo />
      </main>
    </>
  );
};
export default Layout;
