import React, { useCallback, useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";
import useSWR from "swr";
import { AuthApi } from "../../api";
import QpayAskModal from "../../components/QpayAskModal";
import QpayModal from "../../components/auth/Qpay";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

const PopupContent = {
  image: "./images/portfolio/portfolio-04.jpg",
  popupLink: [
    "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
  ],
};

function VideoOne({ data }) {
  const [toggler, setToggler] = useState(false);
  const [select, setSelect] = useState();
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const { data: user } = useSWR("swr.user.me");

  const [qpayModal, setQpayModal] = useState(false);
  const onQpay = () => {
    setQpayModal((prev) => !prev);
  };
  const [show, setShow] = useState(false);
  const [qpay, setQpay] = useState();
  const handleClose = () => {
    setShow(false);
  };

  const onPayment = async () => {
    setLoading(true);
    try {
      const res = await AuthApi.getInvoince({ userId: user?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setShow(false);
      setQpayModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const checkPayment = useCallback(async () => {
    setCheckLoading(true);
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, user?._id);
      if (res.success) {
        window.location.reload();
        // Toast("Төлбөр төлөгдсөн");
      }
      setCheckLoading(false);
    } catch (err) {
      // Toast("Төлбөр төлөгдөөгүй", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   type: "warning",
      // });
      alert(
        "Төлбөр төлөгдөөгүй байна. Хэрэв та төлсөн бол бүртгэлээ гаргаад оруулна уу"
      );
      setCheckLoading(false);
    }
  }, [qpay?.invoiceId]);

  const onToggle = (data) => {
    if (user?.isPayment) {
      setToggler(!toggler);
      setSelect(data);
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <div className="row row--15">
        {data?.map((item) => {
          return (
            <div className="col-lg-6 col-md-6 col-12 mt--30">
              <div className="video-popup icon-center">
                <div className="thumbnail">
                  <img
                    className="radius-small "
                    src={item.image.url}
                    alt="Corporate Image"
                  />
                </div>
                <div className="video-icon">
                  <button
                    className="btn-default rounded-player"
                    onClick={() => onToggle(item)}
                  >
                    <span>
                      <FiPlay />
                    </span>
                  </button>
                </div>
                {/* <FsLightbox toggler={toggler} sources={item.video} /> */}
              </div>
            </div>
          );
        })}
      </div>
      <Lightbox
        open={toggler}
        plugins={[Video]}
        close={() => setToggler(false)}
        video={{
          autoPlay: true,
          controlsList: "nodownload",
        }}
        slides={[
          {
            type: "video",
            width: 1280,
            height: 720,
            poster: select?.image?.url,

            sources: [
              {
                src: select?.video,
                type: "video/mp4",
              },
            ],
          },
        ]}
      />
      <QpayAskModal
        show={show}
        handleClose={handleClose}
        handlePay={onPayment}
        loading={loading}
      />
      <QpayModal
        onClose={onQpay}
        show={qpayModal}
        checkPayment={checkPayment}
        qpay={qpay}
        checkLoading={checkLoading}
      />
    </>
  );
}
export default VideoOne;
