import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";

const Nav = ({ onLogin }) => {
  const { data } = useSWR("swr.user.me");
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Нийтлэл</Link>
      </li>
      <li>
        <Link to="/lesson">Хичээл</Link>
      </li>
      <li>
        <Link to="/advice">Зөвлөмж</Link>
      </li>
      <li>
        <Link to="/exercise">Дасгал</Link>
      </li>
      {!data && (
        <li>
          <Link to="#" onClick={onLogin}>
            Нэвтрэх
          </Link>
        </li>
      )}
    </ul>
  );
};
export default Nav;
