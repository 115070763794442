import { get } from "../helpers/api_helper";

export const getArticles = async () => {
  const res = get("/articles?page=1&limit=20");
  return res;
};
export const getArticle = async (id) => {
  const res = get(`/articles/${id}`);
  return res;
};
