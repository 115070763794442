import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Import Css Here
import "./assets/scss/style.scss";

import MyRoute from "./routes/route";

const App = () => {
  return (
    <Router>
      <MyRoute />
    </Router>
  );
};

export default App;
