import { get } from "../helpers/api_helper";

export const getExercises = async () => {
  const res = get("/exercises?page=1&limit=50");
  return res;
};
export const getExercise = async (id) => {
  const res = get(`/exercises/${id}`);
  return res;
};
