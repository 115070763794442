import React from "react";
import useSWR from "swr";
import { AuthApi } from "../api";
import HeaderOne from "../components/common/HeaderOne";
import SEO from "../components/common/SEO";
import BreadcrumbOne from "../components/common/BreadcrumbOne";

const PrivacyScreen = () => {
  const { data } = useSWR("swr.policy", async () => {
    const res = await AuthApi.privacy();
    return res;
  });

  return (
    <>
      <SEO title="S69.mn" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        <BreadcrumbOne title="Privacy policy" />
        <div
          dangerouslySetInnerHTML={{ __html: data?.text }}
          className="theme-shape pl--20 pr--20 pb--30 pt--30 "
        />
      </main>
    </>
  );
};

export default PrivacyScreen;
