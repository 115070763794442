import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const PricingTwo = ({ handlePay }) => {
  return (
    <div className="rn-pricing">
      <div className="pricing-table-inner">
        <div>
          <h4 className="title">Зөвхөн Бэлгийн боловсрол олгох платформ!</h4>
          <span className="subtitle">
            Та яг одоо, Монгол улс дахь БЗХӨ-ий тархалт, нийгэм дэх гэр бүл
            салалт болон хосуудын аз жаргалгүй амьдралын үндсэн шалтгаануудыг
            багасгах, бууруулах зорилготой нийтлэг ёс зүйн хэм хэмжээг барьж
            нийгмийн хариуцлагын хүрээнд бэлтгэгдсэн s69.mn платформд нэвтрэх
            гэж байна. Иймд та 18 насанд хүрсэн байх шаардлагатай бөгөөд дараах
            нийтлэл, хичээл, зөвлөмж болон дасгалуудтай танилцаж өөрийн бэлгийн
            амьдралтай холбоотой мэдээ мэдээлэл, мэдлэг ойлголтыг нэмэгдүүлэх
            боломжтой юм.
          </span>
          <div className="mt-4" />
          <span className="subtitle ">
            ЭЦЭГ, ЭХЧҮҮДЭД: Хэрэв та насанд хүрээгүй хүүхэдтэй эцэг эх бол
            өөрийн хүүхдийн үзэж харж байгаад анхаарал хандуулж хариуцлагатай
            хандаарай гэж хүсье.
          </span>
        </div>
        <div className="pricing-footer mt-5">
          <Button className="btn-default" onClick={handlePay}>
            Би 18+ настай.
          </Button>
        </div>
        <div className="mt-4">
          <span style={{ fontSize: 14 }}>
            Үйлчилгээний нөхцөлтэй дэлгэрэнгүй
            <Link
              className="text-primary"
              style={{ fontSize: 14 }}
              to="/privacy"
            >
              {" "}
              танилцах.
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
export default PricingTwo;
