import React from "react";
import { FiCalendar, FiEye } from "react-icons/fi";

const BlogDetailsContent = ({ data }) => {
  return (
    <>
      <div className="post-page-banner rn-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content text-center">
                <div className="page-title">
                  <h1 className="theme-gradient">{data.title}</h1>
                </div>
                <ul className="rn-meta-list">
                  <li>
                    <FiEye />
                    {data.seen}
                  </li>
                  <li>
                    <FiCalendar />
                    {data?.createdAt?.slice(0, 10)}
                  </li>
                </ul>
                <div className="thumbnail alignwide mt--60">
                  <img
                    className="w-90 radius"
                    src={data.image.url}
                    alt="Blog Images"
                    style={{ width: 620, height: 350 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogDetailsContent;
