import React from "react";
import { Button } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { Triangle } from "react-loader-spinner";
import Typewriter from "typewriter-effect";

const PricingOne = ({ handlePay, onClose, loading }) => {
  return (
    <div className="rn-pricing">
      <div className="pricing-table-inner">
        <div className="pricing-header">
          <h4 className="title">s69 үйлчилгээний эрх</h4>
          <span
            className="list-style--1"
            style={{ textDecoration: "line-through" }}
          >
            <Typewriter
              options={{
                wrapperClassName: "typewrite color-linear",
                strings: ["50,000₮"],
                autoStart: true,
                loop: true,
              }}
            />
          </span>
          <div className="pricing">
            <div className="price-wrapper">
              <span className="price">20,000</span>
              <span className="currency">₮</span>
            </div>
            <span className="subtitle">
              Нэг удаа төлөөд хязгааргүй хэрэглэх
            </span>
          </div>
        </div>
        <div className="pricing-body">
          <ul className="list-style--1">
            <li>
              <FiCheck /> Нийтлэлүүд
            </li>
            <li>
              <FiCheck /> Хичээлүүд
            </li>
            <li>
              <FiCheck /> Зөвлөмжүүд
            </li>
            <li>
              <FiCheck /> Дасгалууд
            </li>
          </ul>
        </div>
        <div className="pricing-footer">
          <Button
            className="btn-default"
            onClick={handlePay}
            disabled={loading}
          >
            {loading ? (
              <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                <Triangle
                  visible={true}
                  height="40"
                  width="40"
                  color="#3959ed"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              "Идэвхжүүлэх"
            )}
          </Button>
        </div>
        <div className="pricing-footer mt--5">
          <Button
            className="btn-default btn-border"
            onClick={onClose}
            disabled={loading}
          >
            {loading ? (
              <Triangle
                visible={true}
                height="40"
                width="40"
                color="#fb5b82"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Хаах"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PricingOne;
