import React from "react";
import { LessonApi } from "../../api";
import useSWR from "swr";
import BlogPropTwo from "../../components/blog/itemProp/BlogPropTwo";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
import BreadcrumbOne from "../../components/common/BreadcrumbOne";
import { Triangle } from "react-loader-spinner";
const LessonScreen = () => {
  const { data, isLoading } = useSWR("swr.lessons", async () => {
    const res = await LessonApi.getLessons();
    return res;
  });
  return (
    <>
      <SEO title="s69.mn - Хичээл" />
      <Layout>
        <BreadcrumbOne title="Хичээл" />
        {isLoading ? (
          <div
            className="d-flex justify-content-center rn-section-gap"
            style={{ alignSelf: "center" }}
          >
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#fb5b82"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="main-content">
            {/* Start Blog Area  */}
            <div className="rn-blog-area rn-section-gap">
              <div className="container">
                <div className="row mt_dec--30">
                  <BlogPropTwo
                    column="col-lg-6 mt--30"
                    StyleVarProp="box-card-style-default card-list-view"
                    data={data?.data}
                  />
                </div>
              </div>
            </div>
            {/* End Blog Area  */}
          </div>
        )}
      </Layout>
    </>
  );
};
export default LessonScreen;
