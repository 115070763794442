import React, { useState } from "react";
import BlogDetailsContentTwo from "../../components/blog/BlogDetailsContentTwo";
import useSWR from "swr";
import { ExerciseApi } from "../../api";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
import { Triangle } from "react-loader-spinner";

const StoryDetail = ({
  match: {
    params: { id },
  },
}) => {
  const { data, isLoading } = useSWR(`swr.exercise.${id}`, async () => {
    const res = await ExerciseApi.getExercise(id);
    return res.data;
  });

  if (!data) {
    return null;
  }
  return (
    <>
      <SEO title="S69 || Дасгал" />
      <Layout>
        {isLoading ? (
          <div
            className="d-flex justify-content-center rn-section-gap"
            style={{ alignSelf: "center" }}
          >
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#fb5b82"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="rn-blog-details-area">
            <BlogDetailsContentTwo data={data} />
          </div>
        )}
      </Layout>
    </>
  );
};
export default StoryDetail;
