import { get, post } from "../helpers/api_helper";

export const login = async (user) => {
  const res = post("/users/login", user);
  return res;
};

export const register = async (user) => {
  const res = post("/users/register", user);
  return res;
};

export const me = async () => {
  const res = get("/users/me");
  return res;
};
export const privacy = async () => {
  const res = get("/users/privacy");
  return res;
};

export const logout = async () => {
  const res = await get("/users/logout");
  return res;
};
export const checkPayment = async (invoiceId, userId) => {
  const res = await get(`/users/check/challbacks/${invoiceId}/${userId}`);
  return res;
};

export const getInvoince = async ({ userId }) => {
  const res = await post(`/users/invoice/${userId}`, {
    amount: 20000,
  });
  return res;
};

export const adultVerify = async () => {
  const res = await post("/users/adultVerify");
  return res.data;
};
