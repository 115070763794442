import React from "react";
import BlogListThree from "./BlogListThree";

const BlogPropThree = ({ column, StyleVarProp, data }) => {
  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {data?.map((item) => (
            <div key={item.id} className={column}>
              <BlogListThree StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default BlogPropThree;
