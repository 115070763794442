import React from "react";
import useSWR from "swr";
import { ExerciseApi } from "../../api";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
import BreadcrumbOne from "../../components/common/BreadcrumbOne";
import { Triangle } from "react-loader-spinner";
import BlogListThree from "../../components/blog/itemProp/BlogListThree";
import BlogPropThree from "../../components/blog/itemProp/BlogPropThree";
const StoryScreen = () => {
  const { data, isLoading } = useSWR("swr.exercise", async () => {
    const res = await ExerciseApi.getExercises();
    return res;
  });
  return (
    <>
      <SEO title="s69.mn - Дасгал" />
      <Layout>
        <BreadcrumbOne title="Дасгал" />
        {isLoading ? (
          <div
            className="d-flex justify-content-center rn-section-gap"
            style={{ alignSelf: "center" }}
          >
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#fb5b82"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="main-content">
            <div className="rwt-portfolio-area rn-section-gap">
              <div className="container">
                <div className="col-lg-12">
                  <div className="row mt_dec--30">
                    <BlogPropThree
                      column="col-lg-12 mt--30"
                      StyleVarProp="box-card-style-default card-list-view"
                      data={data?.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
export default StoryScreen;
