import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./login.css";
import { Link } from "react-router-dom";
import BackgroundImage from "../../assets/img.png";
import Logo from "../../assets/logo.png";
import { useForm } from "react-hook-form";
import { AuthApi } from "../../api";
const LoginScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    try {
      const res = await AuthApi.login(data);
      localStorage.setItem("s69User", JSON.stringify(res.token));
      setLoading(false);
      window.location.reload();
    } catch (err) {
      setError("password", { message: err.response.data.error.message });
      setLoading(false);
    }
  };

  const onAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=mn.s69",
      "_blank"
    );
  };

  const onApple = () => {
    window.open("https://apps.apple.com/us/app/s69-mn/id6479241731", "_blank");
  };

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <form
        className="shadow p-4 sign-in__form form-group"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Header */}
        <img className="mx-auto d-block mt-4" src={Logo} alt="logo" />
        <div className="h4 mb-2 text-center mt-4">Нэвтрэх</div>
        <Form.Label>Hэр</Form.Label>
        <input
          {...register("username", { required: "Заавал бөглөнө" })}
          type="text"
          placeholder="Hэр"
        />
        {errors.username && (
          <Form.Label className="text-danger mt-2">
            {errors.username.message}
          </Form.Label>
        )}
        <div />
        <Form.Label>Нууц үг</Form.Label>
        <input
          {...register("password", { required: "Заавал бөглөнө" })}
          type="password"
          placeholder="Нууц үг"
        />
        {errors.password && (
          <Form.Label className="text-danger">
            {errors.password.message}
          </Form.Label>
        )}

        {!loading ? (
          <button
            className="btn-default mx-auto d-block mt-5"
            style={{ width: "90%" }}
            type="submit"
          >
            Нэвтрэх
          </button>
        ) : (
          <button
            className="btn-default mx-auto d-block mt-5"
            style={{ width: "90%" }}
            type="submit"
            disabled
          >
            Уншиж байна.....
          </button>
        )}
        <div className="mt-4">
          <span style={{ fontSize: 14 }}>
            Хэрэв танд бүртгэл байхгүй бол?{" "}
            <Link
              className="text-primary"
              style={{ fontSize: 14 }}
              to="/register"
            >
              Бүртгүүлэх
            </Link>
          </span>
        </div>
        <div className="mt-4">
          <h5 style={{ fontSize: 14, textAlign: "center" }}>
            Хэрэв танд iOS үйлдлийн системтэй утас, таблет байгаа бол татах
          </h5>
          <img
            src={require("../../assets/ios.png")}
            className="mx-auto d-block w-100"
            alt="ios"
            style={{ objectFit: "contain", height: 50 }}
            onClick={onApple}
          />
          <h5 className="mt-4" style={{ fontSize: 14, textAlign: "center" }}>
            Хэрэв танд Android үйлдлийн системтэй утас, таблет байгаа бол татах
          </h5>

          <img
            src={require("../../assets/android.png")}
            className="mx-auto d-block w-100"
            alt="android"
            style={{ objectFit: "contain", height: 50 }}
            onClick={onAndroid}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginScreen;
