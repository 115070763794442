import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useSWR from "swr";
import QpayModal from "../../auth/Qpay";
import QpayAskModal from "../../QpayAskModal";
import { AuthApi } from "../../../api";
import ErrorToast from "../../common/error-toast";

const BlogList = ({ data, StyleVar, href }) => {
  const { data: user } = useSWR("swr.user.me");
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);

  const [qpayModal, setQpayModal] = useState(false);
  const onQpay = () => {
    setQpayModal((prev) => !prev);
  };
  const [show, setShow] = useState(false);
  const [qpay, setQpay] = useState();
  const handleClose = () => {
    setShow(false);
  };

  const onPayment = async () => {
    setLoading(true);
    try {
      const res = await AuthApi.getInvoince({ userId: user?._id });
      setQpay({
        qrImage: res.data.qrImage,
        invoiceId: res.data.invoiceId,
        urls: res.data.urls,
      });
      setShow(false);
      setQpayModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const checkPayment = useCallback(async () => {
    setCheckLoading(true);
    try {
      const res = await AuthApi.checkPayment(qpay?.invoiceId, user?._id);
      if (res.success) {
        window.location.reload();
        // Toast("Төлбөр төлөгдсөн");
      }
      setCheckLoading(false);
    } catch (err) {
      setCheckLoading(false);
      alert(
        "Төлбөр төлөгдөөгүй байна. Хэрэв та төлсөн бол бүртгэлээ гаргаад оруулна уу"
      );
      // Toast("Төлбөр төлөгдөөгүй", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   type: "warning",
      // });
    }
  }, [qpay?.invoiceId, data?._id]);

  return (
    <>
      <div className={`rn-card ${StyleVar}`}>
        <div className="inner">
          <div className="thumbnail">
            <Link
              to={
                user?.isPayment
                  ? process.env.PUBLIC_URL + `/${href}/${data.id}`
                  : "#"
              }
              className="image"
              onClick={() => {
                if (!user?.isPayment) {
                  setShow(true);
                }
              }}
            >
              <img
                src={data.image.url}
                // src={data.image.url}
                alt={"Blog Image" + data._id}
                style={{ maxHeight: 250 }}
              />
            </Link>
          </div>
          <div className="content">
            <ul className="rn-meta-list">
              <li>
                {/* <Link
                to={process.env.PUBLIC_URL + `/archive/${slugify(data.author)}`}
              >
                {data.author}
              </Link> */}
              </li>
            </ul>

            <h4 className="title">
              <Link
                to={
                  user?.isPayment
                    ? process.env.PUBLIC_URL + `/${href}/${data.id}`
                    : "#"
                }
                onClick={() => {
                  if (!user?.isPayment) {
                    setShow(true);
                  }
                }}
              >
                {data.title}
              </Link>
            </h4>
          </div>
        </div>
      </div>
      <QpayAskModal
        show={show}
        handleClose={handleClose}
        handlePay={onPayment}
        loading={loading}
      />
      <QpayModal
        onClose={onQpay}
        show={qpayModal}
        checkPayment={checkPayment}
        qpay={qpay}
        checkLoading={checkLoading}
      />
    </>
  );
};
BlogList.propTypes = {
  data: PropTypes.object,
};
export default BlogList;
