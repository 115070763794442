import React from "react";
import { FiLoader, FiX } from "react-icons/fi";
import useSWR from "swr";
import Logo from "../common/Logo";
import { Triangle } from "react-loader-spinner";

const QpayModal = ({ show, onClose, qpay, checkPayment, checkLoading }) => {
  const { data } = useSWR("swr.user.me");
  var elements = document.querySelectorAll(
    ".popup-login-menu .has-droupdown > a"
  );
  var elementsTwo = document.querySelectorAll(
    ".popup-login-menu .with-megamenu > a"
  );
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (var i in elementsTwo) {
    if (elementsTwo.hasOwnProperty(i)) {
      elementsTwo[i].onclick = function () {
        this.parentElement
          .querySelector(".rn-megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <div className={`popup-login-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo
            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
            image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
          />
          <div className="close-menu">
            <span className="close-button" onClick={onClose}>
              <FiX />
            </span>
          </div>
        </div>
        <p
          style={{
            color: "white",
            textAlign: "center",
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          Та QPay ашиглан энэхүү QR-ийг уншуулж төлбөрөө төлөх боломжтой:
        </p>
        <img
          alt="Base64"
          src={`data:image/png;base64,${qpay?.qrImage}`}
          width={300}
          height={300}
          style={{ alignSelf: "center" }}
        />
        <button
          className="btn-default"
          onClick={checkPayment}
          style={{ width: 350, alignSelf: "center", marginTop: 10 }}
          disabled={checkLoading}
        >
          {checkLoading ? (
            <div className="d-flex justify-content-center ">
              <Triangle
                visible={true}
                height="40"
                width="40"
                color="#fb5b82"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            "Шалгах"
          )}
        </button>
        <p
          style={{
            color: "white",
            textAlign: "center",
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
          }}
        >
          эсвэл та өөрийн ашигладаг интернет банкийг сонгон төлөх бол:
        </p>
        <div
          className="row mt--10"
          style={{ alignSelf: "center", justifyContent: "center" }}
        >
          {qpay?.urls?.map((url) => {
            return (
              <a href={url.link} className="row col-4 mt--3" key={url.logo}>
                <img
                  src={url.logo}
                  alt={url.logo}
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "contain",
                  }}
                />
                <p>{url.name}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default QpayModal;
