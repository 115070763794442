import React from "react";
import BlogDetailsContent from "../../components/blog/BlogDetailsContent";
import useSWR from "swr";
import { LessonApi } from "../../api";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
const LessonDetail = ({
  match: {
    params: { id },
  },
}) => {
  const { data } = useSWR(`swr.lesson.${id}`, async () => {
    const res = await LessonApi.getLesson(id);
    return res.data;
  });

  if (!data) {
    return null;
  }
  return (
    <>
      <SEO title="s69.mn || Сургалт" />
      <Layout>
        <div className="rn-blog-details-area">
          <BlogDetailsContent data={data} />
        </div>
      </Layout>
    </>
  );
};
export default LessonDetail;
