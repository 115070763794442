import React from "react";
import BlogList from "./BlogList";

const BlogProp = ({ column, StyleVarProp, data, href }) => {
  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {data?.map((item) => (
            <div key={item.id} className={column}>
              <BlogList StyleVar={StyleVarProp} data={item} href={href} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogProp;
