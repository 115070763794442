import React, { useEffect, useState } from "react";
import { ArticleApi, AuthApi } from "../../api";
import useSWR from "swr";
import BlogProp from "../../components/blog/itemProp/BlogProp";
import SEO from "../../components/common/SEO";
import Layout from "../../components/common/Layout";
import BreadcrumbOne from "../../components/common/BreadcrumbOne";
import AdultAskModal from "../../components/adult-ask-modal";
import { Triangle } from "react-loader-spinner";

const ArticleScreen = () => {
  const [show, setShow] = useState(false);
  const { data: user } = useSWR("swr.user.me", async () => {
    const res = await AuthApi.me();
    return res;
  });
  const { data, isLoading } = useSWR("swr.article", async () => {
    const res = await ArticleApi.getArticles();
    return res;
  });
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (user?.isAdult === false) {
      setShow(true);
    }
  }, [user?.isAdult]);

  return (
    <>
      <SEO title="s69.mn - Нийтлэлүүд" />

      <Layout>
        <BreadcrumbOne title="Нийтлэл" />
        {isLoading ? (
          <div
            className="d-flex justify-content-center rn-section-gap"
            style={{ alignSelf: "center" }}
          >
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#fb5b82"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="main-content">
            {/* Start Blog Area  */}
            <div className="rn-blog-area rn-section-gap">
              <div className="container">
                <div className="row mt_dec--30">
                  <BlogProp
                    column="col-lg-4 col-md-6 col-12 mt--30"
                    StyleVarProp="box-card-style-default"
                    data={data?.data}
                    href={"article-detail"}
                  />
                </div>
              </div>
            </div>
            {/* End Blog Area  */}
          </div>
        )}
      </Layout>
      <AdultAskModal show={show} handleClose={handleClose} />
    </>
  );
};
export default ArticleScreen;
