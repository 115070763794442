import React from "react";
import { FiCheck } from "react-icons/fi";
import Typewriter from "typewriter-effect";
import { Triangle } from "react-loader-spinner";
const PricingOne = ({ logout, onPayment, loading }) => {
  return (
    <div
      className="col-lg-6 col-md-6 col-12 mx-auto"
      style={{ alignSelf: "center" }}
    >
      <div className="rn-pricing active">
        <div className="pricing-table-inner">
          <div className="pricing-header">
            <h4 className="title">s69 үйлчилгээний эрх</h4>
            <span
              className="list-style--1"
              style={{ textDecoration: "line-through" }}
            >
              <Typewriter
                options={{
                  wrapperClassName: "typewrite color-linear",
                  strings: ["50,000₮"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
            <div className="pricing">
              <div className="price-wrapper">
                <span className="price">20,000</span>
                <span className="currency">₮</span>
              </div>
              <span className="subtitle">
                Нэг удаа төлөөд хязгааргүй хэрэглэх
              </span>
            </div>
          </div>
          <div className="pricing-body">
            <ul className="list-style--1">
              <li>
                <FiCheck /> Нийтлэлүүд
              </li>
              <li>
                <FiCheck /> Хичээлүүд
              </li>
              <li>
                <FiCheck /> Зөвлөмжүүд
              </li>
              <li>
                <FiCheck /> Дасгалууд
              </li>
            </ul>
          </div>
          <div className="pricing-footer">
            <a className="btn-default mb--5" href="#" onClick={onPayment}>
              {loading ? (
                <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                  <Triangle
                    visible={true}
                    height="40"
                    width="40"
                    color="#3959ed"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                "Идэвхжүүлэх"
              )}
            </a>
            <p className="subtitle mt--5">Эсвэл</p>
            <a className="btn-default text-underline" href="#" onClick={logout}>
              {loading ? (
                <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                  <Triangle
                    visible={true}
                    height="40"
                    width="40"
                    color="#3959ed"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                "Гарах"
              )}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PricingOne;
