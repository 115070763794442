import React from "react";
import BlogListTwo from "./BlogListTwo";

const BlogPropTwo = ({ column, StyleVarProp, data }) => {
  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {data?.map((item) => (
            <div key={item.id} className={column}>
              <BlogListTwo StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default BlogPropTwo;
